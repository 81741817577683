//site
$primary-h: 205;
$primary-s: 68;
$primary-b: 96;

$unione: #007a52;

$primary: #4fb1f5;
$primary-text: black;
$secondary: #30373d;
$tertiary: #5a6771;
$tertiary-text: white;

$header-slim-bg-color: $secondary;
$header-center-bg-color: white;

//$headings-color: $primary;
//$semibold: 600;

$link-color: $unione;
